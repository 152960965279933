import { agreementsClient } from '../../utils/HttpClients/index'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getResponseData } from '../../utils/HttpClients/helpers'

/**
 * Return all data of requested agreement.
 *
 * @returns {*}
 */
export const loadAgreements = createAsyncThunk('agreements/fetchAgreements', async () => {
    const response = await agreementsClient
        .get('/agreements')
        .then(getResponseData)
        .then((data) => {
            const replaceElements = (text) =>
                text.replace('<link>', '<a href="#">').replace('</link>', '</a>')

            data.data.map((agreement) => {
                agreement.grant_text = replaceElements(agreement.grant_text)

                agreement.l10ns.map((l10n) => {
                    l10n.grant_text = replaceElements(l10n.grant_text)

                    return l10n
                })

                return agreement
            })

            return data
        })

    return response.data
})
