export const ACTION_STATUS_IDLE = 'idle'
export const ACTION_STATUS_PENDING = 'pending'
export const ACTION_STATUS_FULFILLED = 'fulfilled'
export const ACTION_STATUS_REJECTED = 'rejected'

export default {
    ACTION_STATUS_IDLE,
    ACTION_STATUS_PENDING,
    ACTION_STATUS_FULFILLED,
    ACTION_STATUS_REJECTED,
}
