import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectIssuerAndLayoutSettings } from '../../features/globalSettings/globalSettings.selectors'

const Helmet = () => {
    const issuerAndLayoutSettings = useSelector(selectIssuerAndLayoutSettings)
    const { t } = useTranslation(issuerAndLayoutSettings.layout)

    return (
        <ReactHelmet>
            <title>{t('common:title')}</title>
        </ReactHelmet>
    )
}

export default Helmet
