import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    layoutDefined: false,
    showStepperAndTitle: true,

    // -- DefaultLayout
    // pageTitle: '',
    // stepsCount: 0,
    // currentStep: 0,
    // -- DefaultLayout
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        layoutDefined: (state, action) => {
            const newState = { ...state, ...action.payload, layoutDefined: true }
            return newState
        },
        pageTitleUpdated: (state, action) => {
            state.pageTitle = action.payload
        },
        stepUpdated: (state, action) => {
            state.currentStep = action.payload
        },
        setShowStepperAndTitle: (state, action) => {
            state.showStepperAndTitle = action.payload
        },
    },
})

export const { layoutDefined, pageTitleUpdated, stepUpdated, setShowStepperAndTitle } =
    layoutSlice.actions
export default layoutSlice.reducer
