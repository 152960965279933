import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import intervalPlural from 'i18next-intervalplural-postprocessor'

import namespaces from './namespaces'
import languages from './languages'

export const DEFAULT_FALLBACK_LANGUAGE = 'en'

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .use(intervalPlural)
    .init({
        supportedLngs: languages,
        nonExplicitSupportedLngs: true,
        fallbackLng: (code) => {
            const fallbacks = []

            // if is current language an alternative, fallback its main language
            if (code.includes('-')) {
                fallbacks.push(code.split('-')[0])
            }

            // General fallbacks for all languages
            fallbacks.push(DEFAULT_FALLBACK_LANGUAGE)

            return fallbacks
        },
        debug: process.env.REACT_APP_IS_DEBUG === 'true',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        defaultNS: 'common',
        ns: namespaces,
        load: 'languageOnly',
    })

languages.forEach((language) => {
    namespaces.forEach((namespace) => {
        import(`../locales/${namespace}/${language}.json`).then((module) =>
            i18n.addResourceBundle(language, namespace, module.default)
        )
    })
})

// Custom event listener on language change.
// Useful to change currency libraries, moment date libraries etc.
// i18n.on('languageChanged', (languageCode) => {
//     // If is not language supported, change to fallback
//     if (languages.includes(languageCode) === false) {
//         i18n.changeLanguage(DEFAULT_FALLBACK_LANGUAGE)
//
//         return
//     }
// })

export default i18n
