import { camelCase, mapKeys } from 'lodash'

/**
 * Helper to get axios response data.
 *
 * @param response
 */
export const getResponseData = (response) => response.data

/**
 * Helper to camel case global settings data.
 *
 * @param data
 * @returns {*}
 */
export const camelCaseGlobalSettingsResponse = (data) => {
    data.data = mapKeys(data.data, (value, key) => camelCase(key))

    if (data.data.cardOrder) {
        data.data.cardOrder = mapKeys(data.data.cardOrder, (value, key) => camelCase(key))
    }

    if (data.data.payment) {
        data.data.payment = mapKeys(data.data.payment, (value, key) => camelCase(key))
    }

    if (data.data.validityExtensionOrder) {
        data.data.validityExtensionOrder = mapKeys(data.data.validityExtensionOrder, (value, key) =>
            camelCase(key)
        )
    }

    return data
}
