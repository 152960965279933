import React from 'react'
import PropTypes from 'prop-types'

const Content = ({ children }) => {
    return <main className="bg-bg flex-grow p-4 sm:pb-40 pb-8">{children}</main>
}

Content.propTypes = {
    children: PropTypes.any,
}

export default Content
