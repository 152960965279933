import React from 'react'
import PropTypes from 'prop-types'
import Content from '../../Content'

const EmptyLayout = ({ children }) => {
    return (
        <div className="flex flex-col h-screen">
            <Content>{children}</Content>
        </div>
    )
}

EmptyLayout.propTypes = {
    children: PropTypes.any,
}

export default EmptyLayout
