import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const ContentBlock = ({ children, isCentered, ...rest }) => {
    let customClasses = null
    if (rest['className']) {
        customClasses = rest['className']
        delete rest['className']
    }

    return (
        <div
            className={clsx(
                'bg-white rounded-xl mb-10 p-8 pb-6 shadow-default flex flex-col h-full',
                isCentered && 'items-center',
                customClasses && customClasses
            )}
            {...rest}
        >
            {children}
        </div>
    )
}

ContentBlock.propTypes = {
    children: PropTypes.any,
    isCentered: PropTypes.bool,
}
ContentBlock.defaultProps = {
    isCentered: false,
}

export default ContentBlock
