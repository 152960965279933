import { createSlice } from '@reduxjs/toolkit'
import { loadGlobalSettings, loadGlobalSettingsWithPaymentId } from './globalSettings.service'
import { agreementsClient } from '../../utils/HttpClients'

const setTenantHeaderToHttpClients = (tenantCode) => {
    agreementsClient.defaults.headers.common['X-Tenant-Code'] = tenantCode
}

const setSettingsState = (state, action) => {
    state.layout = action.payload.layout || 'defaultLayout'
    state.issuerId = action.payload.issuer_id
    state.issuerName = action.payload.issuer_name
    state.issuerLogoName = action.payload.issuer_logo_name
    state.issuerHasProfilePicture = action.payload.issuer_sections.profile_picture
    state.issuerShortcut = action.payload.issuer_shortcut
    state.tenantCode = action.payload.tenant_code
    state.availableLanguages = action.payload.available_languages
    state.paymentProviders = action.payload.payment_providers
    state.currency = action.payload.currency
    state.priceList = action.payload.price_list
    state.status = 'idle'
    state.isLoaded = true
    state.cardType = action.payload.card_type // this value is also in formData but we need here for Confirmation pages because we get it from paymentId endpoint
    state.orderType = action.payload.order_type
    setTenantHeaderToHttpClients(action.payload.tenant_code)
}

const initialState = {
    layout: 'defaultLayout',
    tenantCode: null,
    issuerName: null,
    issuerId: null,
    issuerLogoName: null,
    issuerHasProfilePicture: false,
    issuerShortcut: null,
    currency: {},
    availableLanguages: [],
    paymentProviders: [],
    priceList: {},
    status: 'idle',
    isLoaded: false,
    cardType: null,
    orderType: null,
}

const globalSettingsSlice = createSlice({
    name: 'globalSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadGlobalSettings.pending, (state) => {
                state.status = 'pending'
            })
            .addCase(loadGlobalSettings.rejected, (state) => {
                state.status = 'rejected'
            })
            .addCase(loadGlobalSettings.fulfilled, (state, action) => {
                setSettingsState(state, action)
            })
            .addCase(loadGlobalSettingsWithPaymentId.pending, (state) => {
                state.status = 'pending'
            })
            .addCase(loadGlobalSettingsWithPaymentId.rejected, (state) => {
                state.status = 'rejected'
            })
            .addCase(loadGlobalSettingsWithPaymentId.fulfilled, (state, action) => {
                setSettingsState(state, action)
            })
    },
})

export const { actions } = globalSettingsSlice
export default globalSettingsSlice.reducer
