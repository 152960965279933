import { createSlice } from '@reduxjs/toolkit'
import { loadGlobalSettings } from '../globalSettings/globalSettings.service'
import { loadFormDetails } from '../form/form.service'
import { loadAgreements } from '../agreements/agreements.service'

const initialState = {
    page500: {
        show: false,
        message: null,
    },
    page404: {
        show: false,
        message: null,
    },
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showPage500: (state, action) => {
            const { show, message } = action.payload

            state.page500.show = show
            state.page500.message = show ? message : null
        },
        showPage404: (state, action) => {
            state.page404.show = action.payload.show
            state.page404.message = action.payload.message
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadGlobalSettings.rejected, (state) => {
            state.page500.show = true
            state.page500.message = 'Chyba při načítání prostředí'
        })
        builder.addCase(loadFormDetails.rejected, (state) => {
            state.page500.show = true
            state.page500.message = 'Chyba při načítání žádosti'
        })
        builder.addCase(loadAgreements.rejected, (state) => {
            state.page500.show = true
            state.page500.message = 'Chyba při načítání souhlasů'
        })
    },
})

export const { actions } = uiSlice
export default uiSlice.reducer
