export const selectState = (state) => ({
    status: state.globalSettings.status,
    isLoaded: state.globalSettings.isLoaded,
})

export const selectTenantAndIssuer = (state) => ({
    tenantCode: state.globalSettings.tenantCode,
    issuerId: state.globalSettings.issuerId,
})

export const selectIssuerAndLayoutSettings = (state) => ({
    layout: state.globalSettings.layout,
    issuerName: state.globalSettings.issuerName,
    issuerLogoName: state.globalSettings.issuerLogoName,
    issuerHasProfilePicture: state.globalSettings.issuerHasProfilePicture,
    issuerShortcut: state.globalSettings.issuerShortcut,
    availableLanguages: state.globalSettings.availableLanguages,
    priceList: state.globalSettings.priceList,
    currency: state.globalSettings.currency,
    cardType: state.globalSettings.cardType,
    orderType: state.globalSettings.orderType,
})

export const selectAvailablePaymentProviders = (state) => state.globalSettings.paymentProviders
