import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    loadGlobalSettings,
    loadGlobalSettingsWithPaymentId,
} from '../../features/globalSettings/globalSettings.service'
import {
    selectState,
    selectTenantAndIssuer,
} from '../../features/globalSettings/globalSettings.selectors'
import { ACTION_STATUS_PENDING } from '../../types/toolkitStatuses'
import Loader from '../../components/Loader'
import EmptyLayout from './EmptyLayout'

const DefaultLayout = React.lazy(() => import('./DefaultLayout'))

const convertIssuerIdToLayout = (issuerId) => {
    switch (issuerId) {
        default:
            return DefaultLayout
    }
}

/**
 * Load specific layout from backend according to received Order GUID.
 */
const Layout = ({ children }) => {
    const dispatch = useDispatch()
    const { requestId, paymentId } = useParams()
    const globalSettingsState = useSelector(selectState)
    const tenantCodeAndIssuer = useSelector(selectTenantAndIssuer)

    useEffect(() => {
        if (requestId) {
            dispatch(loadGlobalSettings(requestId))
        }
        if (paymentId) {
            dispatch(loadGlobalSettingsWithPaymentId(paymentId))
        }
    }, [dispatch, requestId, paymentId])

    if (
        globalSettingsState.status === ACTION_STATUS_PENDING ||
        globalSettingsState.isLoaded === false
    ) {
        return (
            <EmptyLayout>
                <Loader isLoading isCentered size32 />
            </EmptyLayout>
        )
    }

    const PreferredLayout = convertIssuerIdToLayout(tenantCodeAndIssuer.issuerId)

    return (
        <React.Suspense>
            <PreferredLayout>{children}</PreferredLayout>
        </React.Suspense>
    )
}

Layout.propTypes = {
    children: PropTypes.any,
}

export default Layout
