import { configureStore } from '@reduxjs/toolkit'
import UIReducer from '../features/ui/uiSlice'
import globalSettingsReducer from '../features/globalSettings/globalSettingsSlice'
import layoutReducer from '../features/layout/layoutSlice'
import formReducer from '../features/form/formSlice'
import agreementsReducer from '../features/agreements/agreementsSlice'

const store = configureStore({
    reducer: {
        ui: UIReducer,
        globalSettings: globalSettingsReducer,
        layout: layoutReducer,
        form: formReducer,
        agreements: agreementsReducer,
    },
})

export default store
